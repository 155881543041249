import React from 'react'
import { NavLink } from 'react-router-dom'

import { List, ListItem, ListItemText, makeStyles } from '@material-ui/core'
import { ExpandLess } from '@material-ui/icons'

import { PATH_NAME } from 'constants/pathName'
import { useAppAbility } from 'caslManagement/Can'
import { Subjects } from 'caslManagement/ability'
import { SIDE_BAR_MENU } from 'constants/index'

const appMenuItems: { name: string; link: string; icon: Object; items: Object; subject: Subjects }[] = [
    {
        name: SIDE_BAR_MENU.SITE,
        link: PATH_NAME.SITE.INDEX,
        icon: <img src={'/assets/images/site-icon.svg'} alt="" style={{ marginRight: 15 }} />,
        items: [],
        subject: 'Site',
    },
    {
        name: SIDE_BAR_MENU.PROJECT_MANAGEMENT,
        link: PATH_NAME.PROJECT_MANAGEMENT.INDEX,
        icon: <img src={'/assets/images/project-manager-icon.svg'} alt="" style={{ marginRight: 20 }} />,
        items: [],
        subject: 'Project',
    },
    {
        name: SIDE_BAR_MENU.PAYMENT,
        link: PATH_NAME.PAYMENT.INDEX,
        icon: <img src={'/assets/images/payment-icon.svg'} alt="" style={{ marginRight: 15 }} />,
        items: [],
        subject: 'Billing',
    },
    {
        name: SIDE_BAR_MENU.DOWNLOAD,
        link: PATH_NAME.DOWNLOAD.INDEX,
        icon: <img src={'/assets/images/download-icon.svg'} alt="" style={{ marginRight: 20 }} />,
        items: [],
        subject: 'Download',
    },
    {
        name: SIDE_BAR_MENU.SCHEDULE,
        link: PATH_NAME.SCHEDULE.INDEX,
        icon: <img src={'/assets/images/calendar-icon.svg'} alt="" style={{ marginRight: 20 }} />,
        items: [],
        subject: 'Schedule',
    },
    {
        name: SIDE_BAR_MENU.MONITOR_SETTINGS,
        link: PATH_NAME.MONITOR_SETTINGS.INDEX,
        icon: <img src={'/assets/images/monitor-setting-icon.svg'} alt="" style={{ marginRight: 15 }} />,
        items: [],
        subject: 'MonitorSetting',
    },
    {
        name: SIDE_BAR_MENU.NOTE,
        link: PATH_NAME.NOTE.INDEX,
        icon: <img src={'/assets/images/note-icon.png'} alt="" style={{ marginRight: 15 }} />,
        items: [],
        subject: 'Note',
    },
]

export default function AppMenu() {
    const classes = useStyles()
    const ability = useAppAbility()

    const [open, setOpen] = React.useState(false)
    const handleClick = () => {
        setOpen(!open)
    }

    const filteredMenuItems = () => {
        return appMenuItems.filter((menuItem) => ability.can('view', menuItem.subject))
    }

    return (
        <div className={classes.root}>
            <div className={classes.manager}>
                <img src={'/assets/images/ppa_logo.svg'} style={{ marginTop: '5px', marginLeft: '10px', width: '176px', height: '37px' }} alt="" />
            </div>

            <List component="nav" aria-label="secondary mailbox folders" className={classes.nav}>
                <ListItem button component={NavLink} to={PATH_NAME.HOME} exact={true} className={classes.item}>
                    <img src={'/assets/images/home-icon.svg'} alt="" style={{ marginRight: 15 }} />
                    <ListItemText primary="ホーム" />
                    {open ? <ExpandLess onClick={handleClick} /> : <></>}
                </ListItem>

                {filteredMenuItems().map((item, index) => (
                    <ListItem key={index} button component={NavLink} to={item.link} className={classes.item}>
                        {item.icon}
                        <ListItemText primary={item.name} />
                    </ListItem>
                ))}
            </List>
        </div>
    )
}
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 200,
        backgroundColor: '#547096',
        position: 'fixed',
        height: '100%',
        zIndex: 2,
    },
    manager: {
        backgroundColor: '#547096',
        display: 'flex',
        width: '200px',
        height: '50px',
    },
    nav: {
        paddingTop: 0,
        overflowY: 'auto',
        height: 'calc(100% - 170px)',
    },
    item: {
        background: '#6180AB',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        height: 45,
        color: '#fff',
        paddingLeft: 20,
        '& .MuiTypography-body1': {
            fontFamily: 'Hiragino Sans, sans-serif',
            fontSize: '12px',
            fontWeight: 600,
            // whiteSpace: 'nowrap',
            whiteSpace: 'pre-wrap',
            lineHeight: 1.2,
            letterSpacing: 1,
            //
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '&.Mui-selected , &.Mui-selected.MuiListItem-button:hover': {
            backgroundColor: '#547096',
        },
        '&.MuiListItem-button:hover': {
            backgroundColor: '#6180AB',
        },
        '&:nth-child(6)': {
            '& img': {
                marginTop: 5,
                marginLeft: -2,
            },
        },
        '&.active, &.active:hover': {
            backgroundColor: '#547096',
        },
        '&:first-of-type': {
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        },
    },
}))
