import { OrderBy } from './enum'

export const BILLING_TYPE = {
    ALL: 'all', // 一括
    SINGLE: 'single', // 個別
}

export const BILLING_TYPE_FILE = {
    allBilling: 'allBilling',
    singleBilling: 'singleBilling',
    invoiceSample: 'invoiceSample',
}

export const FEE_TYPE = {
    OTHER: 1, // 先方
    SELF: 2, // 当方
}

export const FEE_TYPE_OPTIONS = [
    { value: 1, label: '先方' },
    { value: 2, label: '当方' },
]

export const ROUNDING = {
    ROUND_DOWN: 1,
    ROUND_UP: 2,
    ROUNDING_OFF: 3,
}

export const ROUNDING_OPTIONS = [
    { value: 1, label: '切り捨て' },
    { value: 2, label: '切り上げ' },
    { value: 3, label: '四捨五入' },
]

export const RP_CONTROL_TYPE = {
    NONE: 0,
    PCS_NUMBER_CONTROL: 1,
    REMOTE_MONITORING_SYSTEM: 2,
}

export const RP_CONTROL_OPTIONS = [
    { value: 0, label: 'なし' },
    { value: 1, label: 'PCS台数制御' },
    { value: 2, label: '遠隔監視システム' },
]

export const TAX_ROUNDING_OPTIONS = [
    { value: 1, label: '切り捨て' },
    { value: 2, label: '切り上げ' },
    { value: 3, label: '四捨五入' },
]

export const CHARGE_ROUNDING_OPTIONS = [
    { value: 1, label: '切り捨て' },
    { value: 2, label: '切り上げ' },
    { value: 3, label: '四捨五入' },
]

export const PROJECT_TYPE = {
    SELF_CONSUMPTION: 1,
    SURPLUS: 2,
    PoC: 99,
}

export const PROJECT_TYPE_OPTIONS = [
    { value: 1, label: '自家消費' },
    { value: 2, label: '余剰' },
    { value: 99, label: 'PoC' },
]

// 支払条件
export const PAYMENT_TERMS = [
    { value: 1, label: '月末締翌月末払' },
    { value: 2, label: '検針日締翌検針前日払' },
]

export const PAYMENT_TYPE = {
    BATCH: { value: 'all', label: '一括' },
    INDIVIDUAL: { value: 'single', label: '個別' },
}

export const PAYMENT_COMMON_STATUS = [
    { value: 0, label: '未' },
    { value: 1, label: '済' },
]

export const BILLING_STATUS: any = {
    new: { value: 'new', label: '処理前' },
    unsettled: { value: 'unsettled', label: '未確定' },
    billable: { value: 'billable', label: '確定' },
    billed: { value: 'billed', label: '請求済' },
}

export const CSV_DOWNLOAD_TYPE = [
    { value: 'powerGeneration', label: '太陽光発電量' },
    { value: 'temperature', label: '気温' },
    { value: 'insolation', label: '日射量' },
    { value: 'rpr', label: 'RPR' },
    { value: 'pcsLog', label: 'PCS' },
]

export const CSV_DOWNLOAD_TYPE_VALUE = {
    POWER_GENERATION_1M: 'powerGeneration1m',
    POWER_GENERATION_30M: 'powerGeneration30m',
    SENSOR_1M: 'sensor1m', // no-use
    SENSOR_30M: 'sensor30m', // no-use
    TEMPERATURE_1M: 'temperature1m',
    TEMPERATURE_30M: 'temperature30m',
    INSOLATION_1M: 'insolation1m',
    INSOLATION_30M: 'insolation30m',
    PRP_1M: 'prp1m',
    PRP_30M: 'prp30m',
    PCS_LOG_1M: 'pcsLog1m',
    PCS_LOG_30M: 'pcsLog30m',
}

export const SURPLUS_BUYER_FIT = {
    NON_FIT: 0,
    FIT: 1,
}

export const IS_FIT_OPTIONS = [
    { value: 0, label: 'non-fit' },
    { value: 1, label: 'fit' },
]

export const EQUIPMENT_TYPE_OPTIONS = [
    { value: 'eMeters', label: '電力計' },
    { value: 'smartMeters', label: 'スマートメーター' },
    { value: 'sensors', label: 'センサー' },
    { value: 'battery', label: '蓄電池' },
    { value: 'evCharger', label: 'EV充電器' },
    { value: 'v2h', label: 'V2H' },
    { value: 'pcs', label: 'PCS' },
    { value: 'coreDevice', label: 'コアデバイス' },
    { value: 'remoteIO', label: 'リモートI/O' },
    { value: 'airUnit', label: 'AiR用ユニット' },
    { value: 'storageBattery', label: '産業用蓄電池' },
    { value: 'powerFailureDetector', label: '停電判定' },
]

export const EQUIPMENT_TYPE_REQUIRED = ['eMeters', 'smartMeters', 'sensors', 'battery', 'v2h', 'pcs']

export const AC_TYPE_OPTIONS = [
    { value: 'none', label: 'なし' },
    { value: 'single_phase', label: '単相' },
    { value: 'three_phase', label: '三相' },
]

export const EV_USAGE_OPTIONS = [
    { value: 0, label: 'なし' },
    { value: 1, label: '一般車両用' },
    { value: 2, label: '配達車両用' },
]

export const EQUIPMENT_STATUS = [
    { value: '10', label: '正常' },
    { value: '20', label: '故障' },
    { value: '30', label: '撤去' },
    { value: '40', label: '停止' },
    { value: '50', label: '準備' },
    { value: '60', label: '劣化' },
]

export const REMOTE_CONTROLS = [
    { value: 'reboot', label: 'リブート' },
    { value: 'stop', label: '停止' },
    { value: 'start', label: '起動' },
]

export const EQUIPMENT_ACL = [
    { value: 'ppa', label: 'PPA' },
    { value: 'company', label: '利用企業' },
    { value: 'maintenanceCompany', label: 'メンテナンス事業者' },
]

export const ERROR_HISTORY = {
    PCS: { value: 'pcs', label: 'PCS' },
    RPR: { value: 'rpr', label: 'RPR' },
}

// エラータイプ
export const ERROR_TYPE_OPTIONS = [
    { value: 0, label: '軽故障状態(系統異常)' },
    { value: 1, label: '故障状態(装置異常)' },
]

// エラー内容
export const MIRNOR_FAILURE_CONDITION = [
    { value: 77, label: '系統過電圧(OVR)発生' },
    { value: 67, label: '系統不足電圧(UVR)発生' },
    { value: 57, label: '系統過周波数(OFR)発生' },
    { value: 47, label: '系統不足周波数(UFR)発生' },
    { value: 37, label: 'OVGR発生' },
    { value: 27, label: '単独運転能動発生' },
    { value: 17, label: '単独運転受動発生' },
    { value: 7, label: '系統未接続発生' },
]

export const FAILURE_STATUS = [
    { value: 77, label: '瞬時過電圧or瞬時不足電圧発生' },
    { value: 67, label: 'DCDC異常発生(重故障)' },
    { value: 57, label: '同期外れによる停止発生' },
    { value: 47, label: '直流分漏えい発生' },
    { value: 37, label: '瞬時交流過電流発生' },
    { value: 27, label: 'IPM異常発生(重故障)' },
    { value: 17, label: '直流過電圧発生(重故障)' },
    { value: 7, label: '出力抑制運転発生' },
]

// EV利用用途
export const TARGET_EV = [
    { value: 0, label: 'なし' },
    { value: 1, label: '一般車両用' },
    { value: 2, label: '配達車両用' },
]

export const USER_LEVEL_VALUES = [
    {
        name: 'admin',
        value: 99,
    },
    {
        name: 'maintenanceCompany',
        value: 4,
    },
    {
        name: 'vendor',
        value: 3,
    },
    {
        name: 'company',
        value: 2,
    },
    {
        name: 'site',
        value: 1,
    },
]

export const MQTTT_PUBLISHABLE = [
    { value: 0, label: '非対応' },
    { value: 1, label: '対応' },
]

export const MEASUREMENT_TARGET = [
    { value: 0, label: '対象外' },
    { value: 1, label: '対象' },
]

export const OPEN_24H = [
    { value: 0, label: '営業時間指定' },
    { value: 1, label: '24時間営業' },
]

export const SEND_METEO = [
    { value: 0, label: 'しない' },
    { value: 1, label: 'する' },
]

export const AREA_OPTIONS = [
    { value: 'hokkaido', label: '北海道' },
    { value: 'tohoku', label: '東北' },
    { value: 'tokyo', label: '東京' },
    { value: 'chubu', label: '中部' },
    { value: 'hokuriku', label: '北陸' },
    { value: 'kansai', label: '関西' },
    { value: 'chugoku', label: '中国' },
    { value: 'shikoku', label: '四国' },
    { value: 'kyushu', label: '九州' },
    { value: 'okinawa', label: '沖縄' },
]

export const PROJECT_COST_DETAIL = [
    { value: 0, label: '合計値のみ入力する' },
    { value: 1, label: '項目を追加して詳細を入力する' },
]

export const CATEGORY_SCHEDULE = [
    { value: 0, name: 'maintenance', label: 'メンテナンス', backgroundIcon: '#DEDE1A' },
    { value: 1, name: 'visit', label: '訪問', backgroundIcon: '#B9E2CF' },
    { value: 2, name: 'construction', label: '工事', backgroundIcon: '#ED9C9C' },
    { value: 3, name: 'notice', label: 'お知らせ', backgroundIcon: '#6180AB' },
    { value: 99, name: 'others', label: 'その他', backgroundIcon: '#B4B4B4' },
]

export const DAY_NAMES_JAPAN = ['', '月', '火', '水', '木', '金', '土', '日']

export const INVOICE_NUMBER_ISSUANCE = {
    YES: 1,
    NO: 2,
}

export const SITE_INVOICE_SAMPLE = {
    URL: '/assets/templates/invoice/invoice_site_sample.xlsx',
    FILENAME: '【sample_ single】ご請求書_sitename_yyyymmdd',
}

export const COMPANY_INVOICE_SAMPLE = {
    URL: '/assets/templates/invoice/invoice_company_sample.xlsx',
    FILENAME: '【sample_ all】ご請求書_companyname_yyyymmdd',
}

export const PAGINATION_TYPE = {
    ACCOUNT: 'account',
    COMPANY: 'company',
    PPA: 'ppa',
    MAINTENANCE: 'maintenance',
    MANAGEMENT: 'management',
    SITES: 'sites',
}

export const LIST_DATA_TYPE = {
    LIST_EQUIPMENTS: 'listEquipments',
    LIST_SITES: 'listSites',
    LIST_SITE_IS_PPA_SITE: 'listSites',
    LIST_SITE_WITH_COMPANY_IS_PPA_SITE: 'listSites',
    LIST_SINGLE_BILLINGS: 'listSingleBillings',
    LIST_ALL_BILLINGS: 'listAllBillings',
    LIST_PROJECT: 'listProjects',
    LIST_VENDORS: 'listVendors',
    LIST_COMPANY: 'listCompanys',
    LIST_BASE_COMPANIES: 'listCompanys',
    LIST_BANK_ACCOUNTS: 'listBankAccounts',
    LIST_MONITOR_SETTING: 'listMonitorSettings',
    LIST_COST_ITEM: 'listCostItems',
    LIST_MAINTENANCE_COMPANY: 'listMaintenanceCompanys',
    LIST_INVOICE_SAMPLE: 'listInvoiceSamples',
    LIST_SCHEDULES: 'listSchedules',
}

export const LIST_DATA_TYPE_INDEX = {
    LIST_SINGLE_BILLINGS_BILLING_MONTH: 'billingsOfSiteByBillingMonth',
    LIST_ALL_BILLINGS_BILLING_MONTH: 'billingsOfCompanyByBillingMonth',
}

export const ACCOUNT_TYPE_OPTIONS = [
    { value: 1, label: '普通' },
    { value: 2, label: '当座' },
]

export const ACCOUNT_DATE_OF_ISSUE = [
    { value: 0, label: '発行日当日' },
    { value: 1, label: '検針対象月末日' },
]

export const ACCOUNT_PAYMENT_DEADLINE = [
    { value: 2, label: '翌月20日' },
    { value: 0, label: '翌月25日' },
    { value: 1, label: '翌月末日' },
]

export const PCS_ERROR = [
    { value: 0, label: '正常' },
    { value: 1, label: 'エラー（軽故障）' },
    { value: 2, label: 'エラー（重故障）' },
]

// Home screen
export const FILTER_PCS_ERROR = [
    { label: '-', value: '-' },
    { label: '装置異常', value: 'deviceError' },
    { label: '系統異常', value: 'gridError' },
]

export const FILTER_PCS_ORDER = [
    { label: '発生日時', value: 'date' },
    { label: '利用企業', value: 'company' },
    { label: 'PCSステータス', value: 'status' },
]

export const FILTER_RPR_ORDER = [
    { label: '発生日時', value: 'date' },
    { label: '利用企業', value: 'company' },
    { label: '損失量', value: 'lossPower' },
]

export const FILTER_SORT = [
    { label: '昇順', value: OrderBy.Asc },
    { label: '降順', value: OrderBy.Desc },
]

export const FILTER_SEARCH_ALL = 'すべて'

export const FILTER_MODAL_SCHEDULE = [
    { label: '利用企業', value: '' },
    { label: '全サイト', value: '' },
]

export const TIME = {
    TIME_1M: 1,
    TIME_30M: 30,
}

export const DEFAULT_DISPLAY_VALUE = '-'

export const DEFAULT_SEARCH_VALUE = { value: '', label: FILTER_SEARCH_ALL }
export const BLOB_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

export const DEFAULT_PATH_INVOICE_SITE = '/assets/templates/invoice/invoice_site_sample.xlsx'
export const DEFAULT_PATH_INVOICE_COMPANY = '/assets/templates/invoice/invoice_company_sample.xlsx'
export const INVOICE_DATA_TYPE = 1

export const TYPE_SPAN = {
    ONE_MINUTE: 1,
    THIRTY_MINUTE: 2,
    DAILY: 3,
    WEEKLY: 4,
    MONTHLY: 5,
}

export const RPR_SUB_KEYS = ['receivedPowerAmount', 'K', 'panelCapacity', 'predict', 'lossPower']

export const EQUIPMENT_EMETERS = 'EMeters'

export const SIDE_BAR_MENU = {
    HOME: 'ホーム',
    SITE: 'サイト',
    PROJECT_MANAGEMENT: 'プロジェクト管理',
    PAYMENT: '請求',
    DOWNLOAD: 'ダウンロード',
    SCHEDULE: '予定',
    MONITOR_SETTINGS: '監視設定',
    NOTE: 'リリースノート',
}
