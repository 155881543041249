import { AbilityBuilder, Ability, AbilityClass } from '@casl/ability'
import { UserLevel, UserPermission } from 'constants/enum'
import { Account } from 'types/Account'
import { Company } from 'types/Company'
import { Vendor } from 'types/Vendor'
import { Site } from 'types/Site'
import { Scope } from 'types/Scope'
import { Equipment } from 'types/Equipment'
import { MaintenanceCompany } from 'types/MaintenanceCompany'
import { Schedules } from 'types/Schedule'
import { Project } from 'types'
import { MonitorSetting } from 'types/MonitorSetting'

type Actions = 'manage' | 'view' | 'select' | 'create' | 'modify'
export type Subjects =
    | 'all'
    | 'noAuthorization'
    | 'Vendor'
    | Vendor
    | 'Company'
    | Company
    | 'Site'
    | Site
    | 'Account'
    | Account
    | 'Scope'
    | Scope
    | 'Billing'
    | Project
    | 'Project'
    | 'Download'
    | 'DownloadForecastTab'
    | 'Equipment'
    | Equipment
    | 'Note'
    | MaintenanceCompany
    | 'MaintenanceCompany'
    | 'Schedule'
    | Schedules
    | 'Invoice'
    | MonitorSetting
    | 'MonitorSetting'
export type IAppAbilityManagement = Ability<[Actions, Subjects]>
const AppAbility = Ability as AbilityClass<IAppAbilityManagement>
const abilityManagement = new AppAbility(undefined, {
    // https://casl.js.org/v5/en/guide/subject-type-detection
    detectSubjectType: (object: any) => object!.__typename,
})

export const updateAbilityManagement = (scopes: string[]) => {
    const { can, cannot, rules } = new AbilityBuilder(AppAbility)
    scopes.forEach((scope) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [level, permission, vendorId, companyId, siteId] = scope.split('.')
        switch (level) {
            case UserLevel.Admin:
                can(['select'], 'all')
                if (permission === UserPermission.Full || permission === UserPermission.ReadWrite || permission === UserPermission.Demo) {
                    can('manage', 'all')
                    can('view', ['Invoice'])
                } else if (permission === UserPermission.Read) {
                    can(['view', 'select'], 'all')
                }
                cannot('view', 'noAuthorization')
                break
            case UserLevel.Vendor:
                can(['view'], 'Equipment')
                if (permission === UserPermission.Full || permission === UserPermission.Demo) {
                    can('select', 'Vendor', { id: vendorId })
                    can('select', 'Company', { vendorId: vendorId })
                    can('select', 'Scope', { vendorId: vendorId })
                    can('select', 'Site', { companyId: companyId })
                    can('select', 'MaintenanceCompany', { vendorId: vendorId })
                    can('select', 'Equipment')
                    can('manage', ['Company', 'Site'], { vendorId: vendorId })
                    can(['view', 'modify', 'create'], ['Account'])
                    can('view', ['Billing', 'Download', 'DownloadForecastTab'])
                    can(['modify', 'create'], 'Vendor', { id: vendorId })
                    can('view', ['Note'])
                } else if (permission === UserPermission.Read) {
                    can('select', 'Vendor', { id: vendorId })
                    can('select', 'Company', { vendorId: vendorId })
                    can('select', 'Scope', { vendorId: vendorId })
                    can('select', 'MaintenanceCompany', { vendorId: vendorId })
                    can('select', 'Equipment')
                    can(['view', 'select'], ['Company', 'Site'], { vendorId: vendorId })
                    can('view', ['Billing', 'Download', 'Account', 'DownloadForecastTab'])
                    can('view', ['Note'])
                } else if (permission === UserPermission.ReadWrite) {
                    can('select', 'Vendor', { id: vendorId })
                    can('select', 'Company', { vendorId: vendorId })
                    can('select', 'Scope', { vendorId: vendorId })
                    can('select', 'Site', { companyId: companyId })
                    can('select', 'MaintenanceCompany', { vendorId: vendorId })
                    can('select', 'Equipment')
                    can('manage', ['Company', 'Site'], { vendorId: vendorId })
                    can('view', ['Billing', 'Download', 'DownloadForecastTab'])
                    can('view', ['Note'])
                } else if (permission === UserPermission.Demo) {
                    can('view', ['noAuthorization', 'Download'])
                    can('view', ['Note'])
                }
                break
            case UserLevel.Company:
                can('view', ['noAuthorization'])
                break
            case UserLevel.MaintenanceCompany:
                if (permission === UserPermission.ReadWrite || permission === UserPermission.Demo) {
                    can('select', 'Vendor', { id: vendorId })
                    can('select', 'MaintenanceCompany', { id: companyId })
                    can('select', 'Company', { vendorId: vendorId })
                    can('select', 'Equipment')
                    can(['view'], ['Schedule', 'Download'])
                    can(['view'], ['MaintenanceCompany'], { vendorId: vendorId })
                    can(['view', 'select'], ['Site'], { vendorId: vendorId })
                    can('view', ['Note'])
                }
                break

            case UserLevel.Site:
                can('view', ['noAuthorization'])
                break
            default:
                break
        }
    })

    abilityManagement.update(rules)
}

export default abilityManagement
